import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
let app;
const firebaseConfig = {
  apiKey: process.env.REACT_APP_KEY,
  authDomain: 'portfolio-1f5ca.firebaseapp.com',
  projectId: 'portfolio-1f5ca',
  storageBucket: 'portfolio-1f5ca.appspot.com',
  messagingSenderId: '68944147398',
  appId: process.env.REACT_APP_CLIENT_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app = initializeApp(firebaseConfig);
  }

  database() {
    return getFirestore(app);
  }
}

export default Firebase;
