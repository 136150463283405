import React, { Component, Suspense } from "react";
import Loading from "./Loading";
import Ult from "../threeBackground/Scene";

export default class VideoBackGound extends Component {
  state = {
    loaded: false,
    mobile: false
  };
  constructor() {
    super();
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      loaded: true
    });
  }
  render() {
    const { video } = this.props;
    return (
      <Suspense fallback={<Loading />}>
        <div className="video-overlay">
            <Ult />
        </div>
      </Suspense>
    )
  }
}
