import React from "react";
import styled, { keyframes } from "styled-components";
import { v4 as uuidv4 } from 'uuid';

const progress = keyframes`
  
  from {width:0%; }
  to {width:${props => props.number}0%; }
  `;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: left;
`;
const Item = styled.div`
  display: box;
`;
const List = styled.li`
  color: #afd3d4;
  margin-bottom: 20px;
  padding-left: 1px;
  width:${props => props.number}0%
  list-style: none;
  border-right: 2px solid white;
  animation: ${progress} 2.5s;
`;

export default function Skill(prop) {
    return (
      <Container className="skills info-item">
        {
          prop.skills.map((skillGroup) => {
            return <Item className="field" key={uuidv4()}>
            <br />
            <ul>
              {
                skillGroup.map((skill) => {
                  return <List key={uuidv4()} number={skill.level}>{skill.name}</List>
                })
              }
            </ul>
          </Item>   
          })
        }   
      </Container>
    );
}
