import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';

class Work extends Component {
  render() {
    const { items, index, direction } = this.props;
    return (
      <TransitionGroup
        className="item-container"
        childFactory={child =>
          React.cloneElement(child, {
            classNames: direction
          })
        }
      >
        <CSSTransition key={index} timeout={500} classNames={direction}>
          <div className="item">
            <h1>
              {index + 1} / {items.length}
              <br />
              {items[index].link ? (
                  <a
                    href={items[index].link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {items[index].employer} - {items[index].position}
                  </a>
                ) : 
                <span>{items[index].employer} - {items[index].position}</span>
              }
              
            </h1>
            <h3>{items[index].startDate} - {items[index].endDate}</h3>
            <h3 className="work_stack">{items[index].stack}</h3>
            {items[index].description.length && items[index].description.map((desc) => {
                    return <p key={uuidv4()}>{desc}</p>
                  })}
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

Work.propTypes = {
  items: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired
};

export default Work;
