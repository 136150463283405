import React, { useState, useEffect } from 'react';
import Work from './Work';
import ItemControl from '../layout/ItemControl';
import VideoBack from '../layout/VideoBackGound';
import { Consumer } from '../../context';
import { useFirebase } from '../../firebase-context';
import { collection, orderBy, getDocs, query } from 'firebase/firestore';

export default function Works() {
  const firebase = useFirebase();
  const [works, setWorks] = useState([]);

  useEffect(() => {
    async function getWorks() {
      const querySnapshot = await getDocs(
        query(collection(firebase.database(), 'works'), orderBy('id', 'asc'))
      );
      const result = [];
      querySnapshot.forEach((doc) => {
        result.push(doc.data());
      });
      setWorks(result);
    }

    getWorks();
  }, [firebase]);

  return (
    <Consumer>
      {(value) => {
        const { index, direction } = value;
        return (
          <section className="section projects">
            <VideoBack />
            {works.length && (
              <Work items={works} index={index} direction={direction} />
            )}

            {works.length && (
              <ItemControl length={works.length} name={'Experience'} />
            )}
          </section>
        );
      }}
    </Consumer>
  );
}
