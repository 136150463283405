import React, { Component } from "react";
import Control from "../../components/layout/Control";
import VideoBack from "../../components/layout/VideoBackGound";



class Landing extends Component {
  state = {
    video: "assets/videos/video.mp4",
    hover: false
  };

  onToggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    const { hover } = this.state;
    return (
      <section className="section landing">
        <VideoBack video={this.state.video} />
        <div className="item-container">
          <div className="item main">
            {/* <img src="assets/x_blue.png" alt="X" /> */}
            
            <h1>XIE HUANG<span className="blink">|</span></h1>   
            <p className="blink">
              <span
                onMouseEnter={this.onToggleHover}
                onMouseLeave={this.onToggleHover}
              >
                {hover ? "Thinking Hard" : 'Working Hard'}
              </span>
            </p>
            <p className="landing_p">
            Coffee, Code, and Crushing Challenges: My Recipe for 
              <strong
                onMouseEnter={this.onToggleHover}
                onMouseLeave={this.onToggleHover}
              >
                {" "}
                Kick-ass{" "}
              </strong>
              Software</p>
          </div>
        </div>
        <Control path={this.props.location} />
      </section>
    );
  }
}

export default Landing;
